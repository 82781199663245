@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Inter:wght@300;500&display=swap');

* {
  /* font-size: 62.5%; */
  font-size: 10px;
}

html {
  background-color: #131722;
}

body {
  /* font-family: "league spartan", "inter", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important; */
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif !important;
  background-color: #131722;
}

.wc-event-table {
  /* border: 1px solid black; */
  width: 100%;
}

.wc-event-table {
  border-collapse: collapse;

  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    text-align: left;
    padding-inline: 1rem;
  }

  th {
    font-size: 10px;
    font-weight: 600;
    padding-block: 0.6rem;
    color: gray;
    text-transform: uppercase;
  }

  td {
    font-size: 13px;
    padding-block: 0.7rem;
    color: rgb(62, 62, 62);
  }

  td:first-child,
  th:first-child {
    font-weight: 600;
    color: #009410;
  }
}

.wc-event-table .event-name {
  width: 50%;
}
.wc-event-table .event-cycle {
  /* width: 15%; */
  text-transform: capitalize;
}

.wc-event-table .event-status {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}

.wc-event-table .event-status.enabled {
  color: #009410;
  background-color: #00940f1a;
}

.wc-event-table .event-status.disabled {
  color: #ff6b6b;
  background-color: #ff939546;
}

.wc-event-table-action {
  font-size: 10px;
  font-weight: 600;
  color: rgb(75, 75, 75);
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(201, 201, 201);
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.wc-event-table-action-list {
  position: absolute;
  top: 100%;
  z-index: 1;
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 30px rgba(62, 62, 62, 0.151);
  list-style: none;
  min-width: 150px;
  text-align: left;
}

.wc-event-table-action-list li {
  padding: 0.5rem 0.8rem;
  font-size: 11px;
}

.wc-event-table-action-list li:hover {
  background-color: #fafafa;
}
