:root {
    --black: #414042;
    --white: #fff;
    --pageBackground: #131722;
    --contentBackground: #1e2535;
    --borderWhite: #a7b1b5;
    --borderGray: #434651;
    --primaryDark: #235e37;
    --primaryMain: #00B812;
    --orangeDull: #543a22;
    --commonOrange: #c97f38;
    --console: #0D0F17;
    --purpleHover: #4B147533;
    --grayBorderDark: #CACCD1;
  }
  
  @font-face {
    font-family: 'aquaGrotesque';
    src: local('aquaGrotesque'), url(./fonts/aqua.ttf) format('truetype');
  }
  @font-face {
    font-family: 'exo-mediumItalic';
    src: local('exo-mediumItalic'), url(./fonts//Exo-MediumItalic.otf) format('opentype');
  }
  @font-face {
    font-family: 'exo-bold';
    src: local('exo-bold'), url(./fonts/Exo-Bold.otf) format('opentype');
  }

  .styled-calendar-input {
    width: 8rem;
    height: 1.5rem;
    font-size: 1rem;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black);
    border-bottom: 0.05rem solid var(--grayBorderDark);
    border-top: none;
    border-right: none;
    border-left: none;
    padding: 0.4rem;
    background: url("calendar.svg") no-repeat right;
    background-size: 1.5rem;
    background-position: center right 0.5rem;
    cursor: pointer;
  }
  
  .styled-calendar-input:hover {
    border-bottom: 0.05rem solid var(--primaryMain);
  }
  .styled-calendar-input:focus {
    border-bottom: 0.05rem solid var(--primaryMain);
    outline: none;
  }
  .styled-calendar-input .orange:hover {
    border: 0.1rem solid var(--orangeDull);
  }
  .styled-calendar-input .orange:focus {
    outline: none;
    border: 0.1rem solid var(--commonOrange);
  }
  .react-datepicker__header {
      background-color: var(--white);
  }
  
  
  .react-datepicker__month-dropdown-container {
      margin: 1.5rem 2rem 0.5rem 3rem;
  }
  .react-datepicker__month-read-view {
      background-color: var(--white);
      color: var(--black);
      padding: 0.5rem;
      width: 15rem;
      border: 0.1rem solid var(--black);
      outline: none;
  }
  .react-datepicker__month-read-view--down-arrow {
      right: 1.2rem;
      top: 0.8rem;
      color: var(--primaryMain);
  }
  .react-datepicker__year-dropdown-container {
      margin: 1.5rem 3rem 0.5rem 2rem;
  }
  .react-datepicker__year-read-view {
      background-color: var(--white);
      color: var(--black);
      padding: 0.5rem;
      width: 15rem;
      border: 0.1rem solid var(--black);
      outline: none;
  }
  .react-datepicker__year-read-view--down-arrow {
      right: 1.2rem;
      top: 0.8rem;
  }
  .react-datepicker__month-dropdown {
      background-color: var(--white);
      color: var(--black);
      font-size: 1rem;
      position: absolute;
      width: 50%;
      left: 5%;
      top: 6rem;
      z-index: 1;
      text-align: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
  }
  .react-datepicker__year-dropdown {
      background-color: var(--white);
      color: var(--black);
      font-size: 1rem;
      position: absolute;
      width: 40%;
      left: 55%;
      top: 6rem;
      z-index: 1;
      text-align: center;
      padding-top: 1.5rem;
  }
  
  
  .react-datepicker__month-dropdown--scrollable, 
  .react-datepicker__month-year-dropdown--scrollable, 
  .react-datepicker__year-dropdown--scrollable {
      height: 29rem;
      overflow-y: scroll;
  }
  .react-datepicker__month-option, 
  .react-datepicker__year-option {
    padding: 0.2rem;
  }
  .react-datepicker__month-option:hover, 
  .react-datepicker__year-option:hover {
    background-color: var(--purpleHover);
  }
  
  .react-datepicker {
    background-color: var(--white);
    color: var(--black);
    border: 0.1rem solid var(--black);
    height: 35rem;
  }
  .react-datepicker__navigation-icon::before {
    border-color: var(--primaryMain);
  }
  .react-datepicker__navigation-icon::before:hover {
    border-color: var(--primaryDark);
  }
  .react-datepicker__current-month {
      color: var(--black);
  }
  .react-datepicker__day-name {
      color: var(--black);
  }
  .react-datepicker__day {
      color: var(--black);
  }
  .react-datepicker__day--disabled {
      color: var(--borderGray);
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: var(--primaryMain);
    border-radius: 50%;
    /* box-shadow: inset 0 0 0 0.2rem var(--black); */
    border: 0.1rem solid var(--primaryDark);
    margin: 0rem 0.25rem;
    color: var(--white);
  }
  
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: var(--purpleHover);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--purpleHover);
    border-radius: 50%;
  }
  
  .example-datepicker-disabled {
    cursor: not-allowed
  }
  
  .example-datepicker-class .react-datepicker__today-button {
    color: var(--black);
    background-color: var(--white);
    border-top: 0rem;
    text-align: center;
    margin: 0 0 1rem 0;
  }
  .react-datepicker__today-button:hover {
      color: var(--primaryMain);
  }